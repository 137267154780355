import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './SoftwareCompletion.module.scss'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator/progress_indicator'
import { ConfigContext } from '../../store/ConfigContext'
import { getSplunkRumSpanFlow, Logger, range } from '../../utils/helperMethods'
import useDeviceApi from '../../hooks/useDeviceApi'
import useAnalytics from '../../hooks/useAnalytics'
import {
  CDM_PAYLOAD,
  DEVICE_NOT_SUPPORTED,
  LEDM_PAYLOAD,
  NO_REASON_MESSAGE_DIA,
  SERVICE_ID,
  SPAN_FLOW_TYPE,
  SPAN_WORKFLOW_NAME,
  DEVICE_API_CLIENT_FLOW_TYPE,
  DEVICE_HTTP_PROXY_FLOW_TYPE,
  ANALYTICS,
  SPAN_MODEL_NAME,
  SPAN_PRODUCT_NUMBER,
  RESOURCE_URI_FALLBACK,
  DEVICE_API_STATE_VALUE,
  DEVICE_API_RESOURCE_URI_VALUE
} from '../../configs/constants'
import usePrinter from '../../hooks/usePrinter'
import { PrinterContext } from '../../store/PrinterContext'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

const SoftwareCompletion = () => {
  const {
    closeServiceInstance,
    sessionContext,
    sessionId,
    init: configInit,
    isLedm,
    isCdm,
    fwProtocolCapability,
    publishRumEvent
  } = useContext(ConfigContext)
  const {
    init: printerInit,
    discoveryTree,
    oobeManifestTree,
    productNumber,
    modelName
  } = useContext(PrinterContext)
  const [printerInitiated, setPrinterInitiated] = useState(false)
  const { postDeviceSetupStatus } = useDeviceApi()
  const { patchStatus } = usePrinter(isLedm, discoveryTree, oobeManifestTree)
  const { uiBrand } = useMultiUiBrandContext()

  const { fireScreenDisplayed: fireScreenDisplayedStart } = useAnalytics(
    ANALYTICS.SCREENS.START
  )
  const { fireScreenDisplayed: fireScreenDisplayedEnd } = useAnalytics(
    ANALYTICS.SCREENS.COMPLETED
  )
  fireScreenDisplayedStart()

  const triggerAnalyticsUponCompletion = useCallback(
    (error = null) => {
      fireScreenDisplayedEnd({
        mode: error ? ANALYTICS.FAILURE : ANALYTICS.SUCCESS,
        errorCode: error?.errorCode ? error.errorCode : null
      })
    },
    [fireScreenDisplayedEnd]
  )

  const triggerPublishRumEvent = useCallback(
    (flowType, error, payload, resource) => {
      publishRumEvent(SERVICE_ID, {
        [SPAN_WORKFLOW_NAME]: error?.errorCodeLabel,
        [SPAN_FLOW_TYPE]: flowType,
        [SPAN_MODEL_NAME]: modelName,
        [SPAN_PRODUCT_NUMBER]: productNumber,
        ...getSplunkRumSpanFlow(flowType, isLedm, payload, resource)
      })
    },
    [isLedm, modelName, productNumber, publishRumEvent]
  )

  const patchDeviceHttpProxy = useCallback(
    async (payload) => {
      const response = await patchStatus(payload)
      if (range(200, 299).indexOf(response?.statusCode) !== -1) {
        triggerAnalyticsUponCompletion()
        closeServiceInstance()
        Logger.log('closeServiceInstance result with success')
      } else {
        Logger.error(`Error referencing DHP ${JSON.stringify(response)}`)
        triggerAnalyticsUponCompletion(response)
        triggerPublishRumEvent(
          DEVICE_HTTP_PROXY_FLOW_TYPE,
          response,
          payload,
          isLedm
            ? RESOURCE_URI_FALLBACK.LEDM.oobeConfig
            : RESOURCE_URI_FALLBACK.CDM.deviceSetup
        )
        closeServiceInstance(response)
      }
    },
    [
      closeServiceInstance,
      isLedm,
      patchStatus,
      triggerAnalyticsUponCompletion,
      triggerPublishRumEvent
    ]
  )

  const patchDeviceApiClient = async () => {
    const payload = {
      cloudId: sessionContext?.device?.cloudId,
      state: DEVICE_API_STATE_VALUE,
      header: {
        'X-Correlation-ID': sessionContext?.xCorrelationId
      }
    }
    const apiResponse = await postDeviceSetupStatus(
      payload.cloudId,
      payload.state,
      payload.header
    )
    Logger.log(`Device IOT API response ${JSON.stringify(apiResponse)}`)
    if (apiResponse.status === 'success') {
      triggerAnalyticsUponCompletion()
      closeServiceInstance()
      Logger.log('closeServiceInstance result with success')
      return
    }
    const errorInfo = {
      errorCode: `<${SERVICE_ID}>.<${apiResponse.message}>`,
      errorDescription: NO_REASON_MESSAGE_DIA,
      errorCodeLabel: apiResponse.message
    }
    Logger.error(
      `Error referencing Device IOT API ${JSON.stringify(apiResponse)}`
    )
    triggerAnalyticsUponCompletion(errorInfo)
    triggerPublishRumEvent(
      DEVICE_API_CLIENT_FLOW_TYPE,
      errorInfo,
      payload.state,
      DEVICE_API_RESOURCE_URI_VALUE.replace('$cloudId', payload.cloudId)
    )
    closeServiceInstance(errorInfo)
  }

  useEffect(() => {
    Logger.log('Entered Software Completion Component')
    if (fwProtocolCapability === DEVICE_NOT_SUPPORTED) {
      patchDeviceApiClient()
    } else {
      configInit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sessionId && !printerInitiated) {
      setPrinterInitiated(true)
      printerInit()
    }
  }, [sessionId, printerInit, printerInitiated])

  useEffect(() => {
    if (isCdm && discoveryTree) {
      Logger.log(`Patching DHP with CDM Payload`)
      patchDeviceHttpProxy(CDM_PAYLOAD)
    } else if (isLedm && oobeManifestTree) {
      Logger.log(`Patching DHP with LEDM Payload`)
      patchDeviceHttpProxy(LEDM_PAYLOAD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoveryTree, oobeManifestTree, isLedm, isCdm])
  return (
    <>
      <div className={`${styles.completed} ${styles.spinner}`}>
        <div
          className={
            uiBrand === UiTheme.hpx ? styles.progressBarHPX : styles.progressBar
          }
        >
          <ProgressIndicator
            data-testid="spinner"
            appearance={'circular'}
            behavior={'indeterminate'}
          />
        </div>
      </div>
    </>
  )
}
export default SoftwareCompletion
